import { render, staticRenderFns } from "./import.vue?vue&type=template&id=66947108&scoped=true&"
import script from "./import.vue?vue&type=script&lang=js&"
export * from "./import.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66947108",
  null
  
)

export default component.exports