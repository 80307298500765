<template>
  <div class="divBox">
    <div class="selCard">
      <el-form
        inline
        size="small"
        :model="tableFrom"
        ref="searchForm"
        label-width="120px"
      >

        <el-form-item label="商品分组：" prop="classifyId">
          <el-cascader
            v-model="tableFrom.classifyId"
            class="selWidth"
            :options="classifyList"
            :props="{
              checkStrictly: true,
              emitPath: false,
              label: 'name',
              value: 'id',
            }"
            clearable
          />
        </el-form-item>
        <el-form-item label="商品名称：" prop="name">
          <el-input
          class="selWidth"
            v-model="tableFrom.name"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品状态：" prop="status">
          <el-select
            v-model="tableFrom.status"
            placeholder="请选择"
            class="filter-item selWidth"
            clearable
          >
            <el-option
              v-for="item in productStatusList" 
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="所属品牌" prop="brandId">
          <el-select
            v-model="tableFrom.brandId"
            clearable
            placeholder="请选择"
            class="selWidth"
          >
            <el-option
              v-for="(item, index) in brandList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="所属供应商" prop="vendorId">
          <el-select
            v-model="tableFrom.vendorId"
            clearable
            placeholder="请选择"
            class="selWidth"
          >
            <el-option
              v-for="(item, index) in supplierList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="是否首页推荐:" prop="isRecommendBrand">
          <el-select
            v-model="tableFrom.isRecommendBrand"
            placeholder="请选择"
            class="filter-item selWidth"
            clearable
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="限购:" prop="limitBuyLeastType">
          <el-select
            v-model="tableFrom.limitBuyLeastType"
            placeholder="请选择"
            class="filter-item selWidth"
            clearable
          >
            <el-option
              v-for="item in limitBuyOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item> 
        <el-form-item label="是否为E3:" prop="pushType">
          <el-select
            v-model="tableFrom.pushType"
            placeholder="请选择"
            class="filter-item selWidth"
            clearable
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="是否推荐:" prop="recommend">
          <el-select
            v-model="tableFrom.recommend"
            placeholder="请选择"
            class="filter-item selWidth"
            clearable
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="search()"
            >搜索</el-button
          >
          <el-button size="small" @click="searchReset()">重置</el-button>
          <el-button size="small" @click="exportFunList()">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-card class="mt14">
      <!-- <el-tabs v-model="tableFrom.checkStatus" @tab-click="tabClick">
        <el-tab-pane
          v-for="(item, index) in tabList"
          :key="index"
          :name="item.id"
          :label="item.name"
        />
      </el-tabs> -->
      <div style="margin-bottom: 10px;display: flex;">
        <!-- <el-button size="small" @click="exportGoods">导出商品池</el-button> -->
        <el-button size="small" @click="add">新增商品</el-button>
        <el-button
          v-if="tableFrom.checkStatus == 0"
          size="small"
          @click="batchExamine"
          >批量审核</el-button
        >
        <el-button size="small" @click="batchUp(1)">批量上架</el-button>
        <el-button size="small" @click="batchDown(0)">批量下架</el-button>
        <el-button size="small" @click="batchRecommen(1)"
          >批量推荐商品</el-button
        >
        <el-button size="small" @click="batchRecommen(0)"
          >批量取消推荐</el-button
        >
        <el-button size="small" @click="batchDel()">批量移除</el-button>
        <el-button size="small"  @click="exportExcelFun">商品导入</el-button>

      </div>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        @selection-change="handleSelectionChange"
      >
        <el-table-column key="2" type="selection" width="55" />
        <el-table-column label="商品图" min-width="70">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.mainImg"
                :preview-src-list="[scope.row.mainImg]"
              />
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="brandName" label="品牌名称" min-width="150" />
        <el-table-column prop="name" label="商品名称" min-width="200">
          <template slot-scope="scope">
            <div>
              <span
                class="tags_name"
                :style="
                  scope.row.specType == 0
                    ? 'color: #ff8a4d;'
                    : 'color: #4073FA;'
                "
                :class="'name' + scope.row.spec_type"
                >{{ scope.row.specType == 0 ? "[单规格]" : "[多规格]" }}</span
              >{{ scope.row.name || "-" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="classifyNames" label="分类名称" min-width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.firstCategoryName">
              {{
                `${scope.row.firstCategoryName}/${scope.row.secondCategoryName}`
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="classifyNames"
          label="分组名称"
          min-width="150"
        />
        <!-- <el-table-column label="限购" min-width="150">
          <template slot-scope="scope">
            <div class="demo-image__preview" v-if="scope.row.limitBuyLeastDeptNames||scope.row.limitBuyLeastSexName">
              <div> {{ `限制性别名称:${scope.row.limitBuyLeastSexName||'-'}`}}</div>
              <div>{{`限制部门名称:${scope.row.limitBuyLeastDeptNames||'-'}`}}</div>
        
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column> -->
        <el-table-column prop="E3" label="是否为E3" min-width="90">
          <template slot-scope="scope">
            <div>{{ scope.row.e3 ? "是" : "否" }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="salePrice" label="商品售价(元)" min-width="90" />
        <el-table-column prop="actualSaleNum" label="销量" min-width="70" />
        <el-table-column prop="stock" label="库存" min-width="70" />
        <el-table-column prop="sort" label="排序" min-width="60" />
        <el-table-column prop="status" label="是否推荐" min-width="80">
          <template slot-scope="scope">
            <div>{{ scope.row.recommend == 1 ? "是" : "否" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="是否首页品牌推荐" min-width="120">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isRecommendBrand"
              :active-value="1"
              :inactive-value="0"
              @change="onchangeRecommendBrand(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="status" label="商品状态" min-width="80">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              @change="onchangeStatus(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="180px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
               icon="el-icon-info"
              @click="onDetails(scope.row.id)"
              >详情</el-button
            >
            <el-button type="text"  icon="el-icon-edit" size="small" @click="onEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              v-if="tableFrom.checkStatus == 0"
              type="text"
              size="small"
               icon="el-icon-info"
              @click.native="toExamine(scope.row.id)"
              >审核</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>
    <pro-detail
      ref="proDetail"
      :productId="product_id"
      @closeDrawer="closeDrawer"
      @changeDrawer="changeDrawer"
      :drawer="drawer"
    ></pro-detail>

    <el-dialog
      title="商品池"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <el-form>
        <el-form-item prop="checkStatus" label="审核状态:">
          <el-radio-group v-model="checkStatus" @input="checkStatusChange">
            <el-radio :label="1">审核通过</el-radio>
            <el-radio :label="2">审核未通过</el-radio>
          </el-radio-group></el-form-item
        >
        <el-form-item
          v-if="checkStatus == 2"
          prop="checkStatus"
          label="审核未通过原因:"
        >
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="checkReason"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="e3" label="是否为E3商品:">
          <el-radio-group v-model="e3">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group></el-form-item
        >
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button size="small" type="primary" @click="checkSumbit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="选择商品"
      :visible.sync="dialogGoodsVisible"
      width="1000px"
    >
      <GoodsCardAll v-if="dialogGoodsVisible" @getGoodsItem="getGoodsItem" :storeId="1" />
    </el-dialog>
    <Import v-if="importShow" :show="importShow" :action="importAction" :downloadLink="importDownloadLink"
      :problemLink="importProblem" @close="importClose" />
  </div>
</template>

<script>
import {
  goodsStorePageList,
  goodsPlatformPool,
  goodsCheck,
  storeShelve,
  goodBrandListAll,
  goodsSet,
  goodsClassifyList,
  storeDel,
  goodBrandTotalListAll,
  goodsImport
} from "@/api/goods/goods";
import {
  goodsClassifyStoreList,
  exportData
} from "@/api/store/goodsClassify";
import Import from "./components/import.vue";
import { exportExcel } from "@/assets/utils/exportExcel";
import GoodsCardAll from "./components/goodsCardAll.vue";
import { getSupplierListAll } from "@/api/supplier/supplier";
import proDetail from "./components/proDetails.vue";
import GoodsView from "@/components/goodsView/goodsView.vue";
export default {
  name: "ProductExamine1",
  components: {
    proDetail,
    GoodsCardAll,
    Import,
    GoodsView
  },
  data() {
    return {
      importShow: false,
      importAction: '',
      importDownloadLink: '',
      importProblem: '',
      dialogGoodsVisible: false,
      fileList: [],
      showUpLoad: false,
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        classifyId:'',
        name: "",
        status: "",
        brandId: "",
        vendorId: "",
        isRecommendBrand:"",
        classifyType:''
      },
      merCateList: [],
      listLoading: false,
      tableData: {
        checkStatus: "1",
      },
      productStatusList: [
        {
          label: "上架",
          value: 1,
        },
        {
          label: "下架",
          value: 0,
        },
      ],
      statusOptions: [
        {
          label: "是",
          value: 1,
        },
        {
          label: "否",
          value: 0,
        },
      ],
      limitBuyOptions: [
        {
          label: "所有类型（无限制）",
          value: '0',
        },
        {
          label: "部门",
          value: '1',
        },{
          label: "性别",
          value: '2',
        },{
          label: "部门和性别",
          value: '1,2',
        },
      ],
      drawer: false,
      product_id: "",
      classifyList: [], //分类
      supplierList: [], //供应商
      brandList: [], //品牌列表
      selectionId: [], //选中得id
      dialogVisible: false, //控制审核弹框
      checkStatus: 1, //	审核状态 1-审核通过；2-审核未通过
      e3: true, //是否为e3商品
      checkReason: "", //	审核未通过原因
      tabList: [
        {
          id: "1",
          name: "出售的商品",
        },
        {
          id: "0",
          name: "待审核商品",
        },
        {
          id: "2",
          name: "审核未通过商品",
        },
      ],
    };
  },
  mounted() {
    this.getList();
    this.getClassifyList();
    this.getSupplierList();
    this.getBrandList();
    // this.getMerSelect()
    // this.getList()
    // this.getCategorySelect()
    // this.getLstFilterApi()
    // this.getLabelLst()
  },
  methods: {
        // 导入弹框关闭
        importClose(e) {
      this.importShow = false
      if (e === 1 || e === 2) {
        this.getList();
      } 
    },
    exportFunList() {
      exportExcel("/goods/web/goods/store/pageList/export", {
        ...this.tableFrom,
        time: "",
        pageNum: 1,
        responseType: "blob",
        exportExcelName:"商品列表"
      });
    },
    exportExcelFun(){
      //导入
      this.importAction = '/api/goods/web/goods/store/goods/import';
      //下载模板
      this.importDownloadLink = '';
      //下载问题链接
      this.importProblem = '/api/goods/web/goods/error/export';
      this.importShow = true;
    },

    search(){
     this.tableFrom.pageNum=1
     this.getList()
    },
    handleClose() {
      this.checkStatus = 1;
      this.checkReason = "";
      this.e3 = true;
      this.dialogVisible = false;
    },
    //是否首页展示
    onchangeRecommendBrand(row) {
      goodsSet({ id: row.id, status: row.isRecommendBrand })
        .then(({ msg }) => {
          this.getList();
          this.$message.success(msg);
        })
        .catch(({ msg }) => {
          this.getList();
        });
    },
    //审核状态改变事件
    checkStatusChange(e) {
      this.checkReason = "";
    },
    //批量审核
    batchExamine() {
      if (this.selectionId.length == 0) {
        this.$message.error("请选择操作数据");
        return;
      }
      this.dialogVisible = true;
    },
    //审核确定按钮
    checkSumbit() {
      goodsCheck({
        ids: this.selectionId,
        e3: this.e3,
        checkStatus: this.checkStatus,
        checkReason: this.checkReason,
      }).then((res) => {
        if (res.code === 200) {
          this.getList();
          this.$message.success("操作成功");
          this.dialogVisible = false;
          that.getList();
        }
      });
    },
    toExamine(id) {
      this.selectionId = [id];
      this.dialogVisible = true;
    },
    onchangeStatus(row) {
      storeShelve({
        ids: [row.id],
        status: row.status,
        type: 1,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.getList();
        }
      }).catch((error)=>{
        this.getList();
      });
    },
    //批量上架
    batchUp(val) {
      if (this.selectionId.length == 0) {
        this.$message.error("请选择操作数据");
        return;
      }
      storeShelve({
        ids: this.selectionId,
        status: val,
        type: 1,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.getList();
        }
      });
    },
    //批量推荐
    batchRecommen(val) {
      if (this.selectionId.length == 0) {
        this.$message.error("请选择操作数据");
        return;
      }
      storeShelve({
        ids: this.selectionId,
        recommend: val,
        type: 2,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.getList();
        }
      });
    },
    //批量移除
    batchDel() {
      if (this.selectionId.length == 0) {
        this.$message.error("请选择操作数据");
        return;
      }
      this.$confirm("是否移除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          storeDel({
            ids: this.selectionId,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //批量下架
    batchDown(val) {
      if (this.selectionId.length == 0) {
        this.$message.error("请选择操作数据");
        return;
      }
      storeShelve({
        ids: this.selectionId,
        status: val,
        type: 1,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.getList();
        }
      });
    },
    //多选得id
    handleSelectionChange(val) {
      this.multipleSelection = val;
      const data = [];
      this.multipleSelection.map((item) => {
        data.push(item.id);
      });
      this.selectionId = data;
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.tableFrom.pageNum = val;
      this.getList();
    },
    //获取商品分类列表
    getClassifyList() {
      goodsClassifyStoreList({}).then((res) => {
        this.classifyList = res.data;
      });
    },
    //获取供应商列表
    getSupplierList() {
      getSupplierListAll({}).then((res) => {
        this.supplierList = res.data;
      });
    },
    //获取品牌列表
    getBrandList() {
      goodBrandTotalListAll(1).then((res) => {
        this.brandList = res.data;
      });
    },
    tabClick() {
      this.tableFrom.pageNum = 1;
      this.getList();
    },
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.getList(1);
    },
    add() {
      this.dialogGoodsVisible = true;
    },
    getGoodsItem(e) {
      console.log("e", e);
      this.$router.push({
        path: "/storeGoods/create",
        query: {
          goodsId: e.id,
        },
      });
    },
    getList() {
      this.listLoading = true;
      //type 1 一级分类 2 二级分类 3 三级分类
      if(this.tableFrom.classifyId){
        this.classifyList.map((item)=>{
          if(item.id == this.tableFrom.classifyId){
            this.tableFrom.classifyType = 1
          }
          if(item.children){
            item.children.map((items)=>{
              if(items.id == this.tableFrom.classifyId){
                this.tableFrom.classifyType = 2
              } 
              if(items.children){
                items.children.map((itemss)=>{
                  if(itemss.id == this.tableFrom.classifyId){
                    this.tableFrom.classifyType = 3
                  }
                })
              }
            })
          }
        })
      }else{
        this.tableFrom.type=''
      }
      goodsStorePageList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    changeDrawer(v) {
      this.drawer = v;
    },
    closeDrawer() {
      this.drawer = false;
    },
    //编辑
    onEdit(row) {
      this.$router.push({
        path: "/storeGoods/create",
        query: {
          goodsId: row.goodsId,
          id: row.id,
        },
      });
    },
    //商品详情
    onDetails(id) {
      this.product_id = id;
      this.drawer = true;
      this.$refs.proDetail.getInfo(id);
    }
  },


};
</script>

<style lang="scss" scoped>
/deep/.el-select-dropdown__item {
  max-width: 350px !important;
}
.template {
  overflow: hidden;
}
.label-list {
  height: 100%;
}
.bg {
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}
.tags_name {
  font-size: 10px;
  height: 16px;
  line-height: 16px;
  padding: 0 2px;
  margin-right: 2px;
  &.name0 {
    color: var(--prev-color-primary);
  }
  &.name1 {
    color: #ff8a4d;
  }
}
.rate_star {
  position: relative;
  top: 5px;
}
table .el-image {
  display: inline-block;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand {
  /deep/ label {
    width: 105px;
    color: #99a9bf;
  }
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 33.33%;
}
.seachTiele {
  line-height: 35px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  font-size: 12px;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
