<template>
  <div>
    <el-dialog
        title="提示"
        :visible.sync="uploadShow"
        width="30%"
        :before-close="handleClose">
      <div class="flex align-items justify-content-center marginBottom20">
        <el-upload
            class="upload-demo"
            :drag="true"
            v-loading="loading"
            element-loading-text="导入中" 
            :on-error="handleError"
            :action="action"
            :show-file-list="false"
            :on-success="handleSuccess"
            :before-upload="beforeUpload">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <!-- <div class="flex align-items justify-content-center">
        <el-button type="text" size="medium" @click="handleDownload">下载文件模板</el-button>
      </div> -->
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisibleOne"
        width="30%"
        :before-close="handleCloseOne">
      <div v-if="problemState === 1" style="text-align: center;margin-bottom: 20px">
        <p>导入成功</p>
      </div>
      <div v-if="problemState === 2" style="text-align: center">
        <p>部分数据已导入成功</p>
        <p>问题数据请通过下载获取</p>
      </div>
      <div v-if="problemState === 3" style="text-align: center">
        <p>全部导入失败</p>
      </div>
      <div v-if="problemState === 4" style="text-align: center;margin-bottom: 20px">
        <p>数据为空导入失败</p>
      </div>
      <div v-if="problemState === 3 || problemState === 2" class="flex align-items justify-content-center marginBottom10">
        <el-button type="text" size="medium" @click="handleDownloadProblem">下载问题数据</el-button>
      </div>
      <div class="flex align-items justify-content-center">
        <el-button type="primary" size="medium" @click="handleCloseOne">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "batchHouse",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: ''
    },
    downloadLink: {
      type: String,
      default: ''
    },
    problemLink: {
      type: String,
      default: ''
    }
  },
  data(){
    return{
      uploadShow: this.show,
      dialogVisibleOne: false,
      problemState: 0,
      problemId: '',
      loading: false,
    }
  },
  methods: {
    // 下载问题数据
    handleDownloadProblem(){
      window.open(`${this.problemLink}?id=${this.problemId}`, '_self')
    },
    // 关闭弹框
    handleClose(){
      setTimeout(()=>{
        this.dialogVisibleOne = true;
      },200)
      this.$emit('close',this.problemState)
    },
    handleCloseOne(){
      this.$emit('close',this.problemState)
    },
    handleError(e){
      this.$message.error('上传失败')
      this.loading = false
    },
    handleSuccess(res, file) {
      console.log("handleSuccess");
      // console.log(1111111,res, file);
      this.loading = false
      this.uploadShow = false;
      if (res.code === 200) {
        this.problemState = res.data.state;
        setTimeout(() => {
          this.problemId = res.data.id;
          this.dialogVisibleOne = true;
        }, 500);
      }
    },
    beforeUpload(file) {
      try {
        // 文件类型
        const isType = file.type === "application/vnd.ms-excel";
        const isSafari = file.type == "";
        const isTypeComputer =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const fileType = isType || isTypeComputer || isSafari;
        console.log(fileType, "文件类型");
        if (!fileType) {
          console.log(fileType, "文件类型");
          this.$message.error("上传文件只能是xls/xlsx格式！");
          return
        } // 文件大小限制为2M
        const fileLimit = file.size / 1024 / 1024 < 20;
        if (!fileLimit) {
          this.$message.error("上传文件大小不超过20M！");
          return
        }
        this.loading = true
        return fileType && fileLimit;
      } catch (error) {
        this.loading = false
      }
    },
    // 下载文件模板
    handleDownload(){
      console.log('下载文件模板',this.downloadLink)
      window.open(this.downloadLink, '_self')
    },
  }
}
</script>

<style scoped>

</style>